<template>
	<div class="wrapper wrapper-content animated fadeInRight">
		<div class="row">
			<div class="col-lg-12">
				<div class="tabs-container">
					<ul class="nav nav-tabs">
						<!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
					</ul>

					<div class="tab-content">
						<div class="tab-pane active">
							<div class="panel-body">
								<fieldset>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">
											Formatted
										</label>
										<div class="col-sm-10">
											<textarea id="originalData" class="form-control json" v-html="formattedData" readonly/>
												<span class="input-group-append copy">
													<button type="button" class="btn btn-primary" @click="onClickCopyToClipboard">{{ 'Copy to Clipboard' }}</button>
												</span>
										</div>
									</div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">#</label>
										<div class="col-sm-10">
											<input v-model="data['uid']" class="form-control" type="text" name="uid" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Language Code</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input v-model="data['language_code']" class="form-control" type="text" name="language_code"/>
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkReceipt">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">KO</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="parseData(data.language_data, 'ko')" @input="onInputForm('ko', $event)" class="form-control" type="text" name="ko" />
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkUser">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">EN</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="parseData(data.language_data, 'en')" @input="onInputForm('en', $event)" class="form-control" type="text" name="en" />
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkUser">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">CN</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="parseData(data.language_data, 'cn')" @input="onInputForm('cn', $event)" class="form-control" type="text" name="cn" />
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkUser">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">HK</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="parseData(data.language_data, 'hk')" @input="onInputForm('hk', $event)" class="form-control" type="text" name="hk" />
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkUser">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">JP</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="parseData(data.language_data, 'jp')" @input="onInputForm('jp', $event)" class="form-control" type="text" name="jp" />
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkUser">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">VN</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="parseData(data.language_data, 'vn')" @input="onInputForm('vn', $event)" class="form-control" type="text" name="vn" />
												<!-- <span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkUser">{{ 'Link' }}</button>
												</span> -->
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label"
											>번역 여부</label
										>
										<div class="col-sm-10">
											<input-checkbox class="checkbox" :name="'translated_check'" :expected="1" v-model="data.translated" :ripple="true">{{data.translated ? '번역됨' : '번역 안 됨'}}</input-checkbox>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label"
											>사용 여부</label
										>
										<div class="col-sm-10">
											<input-checkbox class="checkbox" :name="'active_check'" :expected="1" v-model="data.active" :ripple="true">{{data.active ? '사용중' : '사용 안 함'}}</input-checkbox>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Created At</label>
										<div class="col-sm-10">
											<input :value="data.created_at ? $moment(data.created_at).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="created_at" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Updated At</label>
										<div class="col-sm-10">
											<input :value="data.updated_at ? $moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="updated_at" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>
								</fieldset>

								<div class="form-group row">
									<div class="actions">
										<div class="left">
											<button class="btn btn-white btn-sm" @click="onClickBack">Back</button>
										</div>

										<div class="right">
											<button class="btn btn-success btn-sm" :disabled="sending" @click="onClickSubmit">저장</button>
											<button class="btn btn-danger btn-sm" :disabled="sending" @click="onClickDelete">삭제</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import config from '@/config';
import Editor from '@/components/utils/Editor';
import ExtraEditor from '@/components/utils/ExtraEditor';
import ResourceEditor from '@/components/utils/ResourceEditor';
import moment from 'moment-timezone';
import utils from '@/utils';

export default {
	data() {
		return {
			mode: 'unset',
			model: {},
			data: {},
			sending: false,
      items: [],
		};
	},
	mounted() {
		this.$data.mode = this.$route.meta.mode;
		this.$data.model = this.$route.meta.model;

		this.loadData();
	},
	watch: {
		data(value, oldValue) {},
  },
  computed: {
    formattedData(){
      let data = {}
            
      let value = this.data.language_data ? utils.parseJSON(this.data.language_data) : {}

      data[this.data.language_code] = value
      
      return JSON.stringify(data, null, 2)
    }
  },
	methods: {
		loadData() {
			this.$store
				.dispatch('translation/get', this.$route.params)
				.then((data) => {
					this.$data.data = data;
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
    },
    
    parseData(string, key){
      return (string != null && string != '') ? utils.parseJSON(string)[key] : ''
		},

		async onClickSubmit(){
			try{
				let res = await this.$store.dispatch('translation/edit', this.$data.data);

				this.$toast.success("수정되었습니다.")
				this.$router.replace({
					name: "ListTranslation"
				})
			}catch(e){}
		},

		onClickDelete(){
			this.$dialog
				.confirm(`${this.data.uid} 번역을 삭제할까요?`, {
					okText: '확인',
					cancelText: '닫기',
				})
				.then(async (dialog) => {
					await this.onDelete(this.data.uid)
					this.$router.replace({
						name: 'ListTranslation'
					})
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
		},

		async onDelete(id){
			let res

			try{
				res = await this.$store.dispatch(this.model.types.delete.action, {uid: this.$data.data.uid})
				this.$toast.success('삭제되었습니다.')
			}
			catch(e){
				this.$toast.error(e)
			}
		},

		onClickCopyToClipboard(){
			try{
				let copyText = document.getElementById('originalData');

				copyText.select();
				copyText.setSelectionRange(0, 99999);  

				document.execCommand("copy");

				this.$toast.success("클립보드에 저장하였습니다.")
			}
			catch(e){}
		},


    onInputForm(key, e){
			let data = utils.parseJSON(this.data.language_data)
			data[key] = e.target.value;		
      this.data.language_data = JSON.stringify(data);
		},
		
		onClickBack(e){
			this.$router.go(-1)
		}

	},
	components: {
		Editor,
		ExtraEditor,
		ResourceEditor,
	},
};
</script>

<style lang="scss" scoped>
	.json{
		min-height: 20rem !important;
		resize: none;
		// height: 10rem;
		// display: block;
	}

	.copy{
		// position:absolute;
		transform: translateY(-100%);
	}


</style>
